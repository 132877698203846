import React from 'react'
import { useEventBranding } from '~brix/connectors/context/useEventBranding'
import { AppBar, Avatar, Stack, Typography } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const Header = () => {
  const classes = useStyles()
  const { branding } = useEventBranding()

  return (
    <AppBar
      elevation={0}
      className={classes.root}
      id='messenger-header'
      tabIndex={-1}
      aria-labelledby='messenger-botname'
    >
      <Stack alignItems={'center'} direction='row' justifyContent='space-between' p={1}>
        <Stack direction='row' alignItems={'center'}>
          <Avatar src={branding.display.businessIconUrl} sx={{ mr: 1 }} alt='Bot Avatar' />
          <Typography variant='h2' className={classes.title} id='messenger-botname'>
            {branding.botName}
          </Typography>
        </Stack>
        <Stack alignItems={'inherit'} color='white' direction='row' pl={1}></Stack>
      </Stack>
    </AppBar>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    borderRadius: spacing(0.5, 0.5, 0, 0),
  },
  introText: {
    marginLeft: spacing(1),
  },
  title: {
    color: palette.primary.contrastText,
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: 1.3,
  },
}))

export default Header
